import React from "react";
import {StyledHero, StyledHeroContent, StyledHeroIcon, StyledHeroContentText} from "./styledHero";
import {StyledContent} from "../../styles/styledContent";
import {useTranslation} from "react-i18next";
import Lightning from '../../styles/lightning.png';
import {useTransform, useViewportScroll, motion} from "framer-motion";

function Hero() {
    const {t} = useTranslation(['hero']);
    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY, [0,500], [0, -300]);

    return (
            <StyledContent>
                <StyledHero>
                    <StyledHeroContent>
                        <StyledHeroContentText>
                            <h1>{t('head')}<br/>{t('head_1')}</h1>
                            <h3>{t('text')}<br/>{t('text_1')}</h3>
                        </StyledHeroContentText>
                        <StyledHeroIcon>
                            <motion.div
                                className="box"
                                style={{ y: y2 }}
                            >
                                <img draggable={false} src={Lightning} alt={'lightning'}/>
                            </motion.div>
                        </StyledHeroIcon>
                    </StyledHeroContent>
                </StyledHero>
            </StyledContent>
    )
}

export default Hero