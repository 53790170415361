import React from "react";
import {StyledContent} from "../../styles/styledContent";
import {useTranslation} from "react-i18next";
import {
    StyledDataPrivacy,
    StyledDataPrivacyContent,
    StyledImprint,
    StyledImprintHeader,
    StyledImprintHeaderItem,
    StyledDataPrivacyContentParagraph
} from "./styledImprint";

function Imprint() {
    const {t} = useTranslation(['site']);

    return (
        <StyledContent>
            <StyledImprint>
                <StyledImprintHeader>
                    <StyledImprintHeaderItem>
                        <h3>House Of Codes</h3>
                        <h4>Berliner Straße 5</h4>
                        <h4>37073 Göttingen</h4>
                    </StyledImprintHeaderItem>
                    <StyledImprintHeaderItem>
                        <h3>Büro</h3>
                        <h4>Berliner Straße 1</h4>
                        <h4>37073 Göttingen</h4>
                    </StyledImprintHeaderItem>
                    <StyledImprintHeaderItem>
                        <h3>Handelsregister</h3>
                        <h4>Registergericht: Göttingen</h4>
                        <h4>Register-Nr: -folgt-</h4>
                    </StyledImprintHeaderItem>
                    <StyledImprintHeaderItem>
                        <h3>Umsatzsteuer-ID</h3>
                        <h4>§27 a Umsatzsteuergesetz: -folgt-</h4>
                    </StyledImprintHeaderItem>
                    <StyledImprintHeaderItem>
                        <h3>Vertreten durch</h3>
                        <h4>Giulian Thunert</h4>
                    </StyledImprintHeaderItem>
                    <StyledImprintHeaderItem>
                        <h3>Kontakt</h3>
                        <h4>hello@houseofcodes.de</h4>
                    </StyledImprintHeaderItem>
                </StyledImprintHeader>
            </StyledImprint>
            <StyledDataPrivacy>
                <h3>Datenschutz:</h3>
                <h4>{t('privacy.1')}</h4>
                <StyledDataPrivacyContent>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.2')}</h4>
                        <h4>{t('privacy.3')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.4')}</h4>
                        <h4>{t('privacy.5')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.6')}</h4>
                        <h4>{t('privacy.7')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.8')}</h4>
                        <h4>{t('privacy.9')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.10')}</h4>
                        <h4>{t('privacy.11')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.12')}</h4>
                        <h4>{t('privacy.13')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.14')}</h4>
                        <h4>{t('privacy.15')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.16')}</h4>
                        <h4>{t('privacy.17')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.18')}</h4>
                        <h4>{t('privacy.19')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.20')}</h4>
                        <h4>{t('privacy.21')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.22')}</h4>
                        <h4>{t('privacy.23')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.24')}</h4>
                        <h4>{t('privacy.25')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.26')}</h4>
                        <h4>{t('privacy.27')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.28')}</h4>
                        <h4>{t('privacy.29')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.30')}</h4>
                        <h4>{t('privacy.31')}</h4>
                    </StyledDataPrivacyContentParagraph>
                    <StyledDataPrivacyContentParagraph>
                        <h4>{t('privacy.32')}</h4>
                        <h4>{t('privacy.33')}</h4>
                    </StyledDataPrivacyContentParagraph>
                </StyledDataPrivacyContent>
            </StyledDataPrivacy>
        </StyledContent>
    )
}

export default Imprint