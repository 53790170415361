export const darkTheme = {
    identifier: 'dark',
    breakpoints: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px'
    },
    header: {
        background: 'white',
        backgroundColorBlurred: 'rgba(255,255,255,0.5)',
        color: 'white',
        border: 'white'
    },
    slideIn: {
        background: 'black',
        close: '#a5a5a5',
        color: '#e4ff3d'
    },
    common: {
        green: {
            base: '#e4ff3d',
            secondary: '#dcf92d'
        }
    },
    cookieBanner: {
        background: 'white',
        color: 'black',
        border: '#e0e0e0',
        menuItem: '#a5a5a5'
    }
}