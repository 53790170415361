import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledHeader = styled.div`
  padding: 1.2em 1em;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: ${props => props.showHeaderBlur ? props.theme.header.backgroundColorBlurred : props.theme.header.background};
  -webkit-transition: background-color 5000ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  backdrop-filter: ${props => props.showHeaderBlur ? 'blur(20px) saturate(180%)' : 'none'};
  border-bottom: ${props => !props.showHeaderBlur ? '1px solid' : 'none'};
  border-color: ${props => !props.showHeaderBlur ? props.theme.header.border : 'none'};
  color: ${props => props.theme.header.color};
`;

const StyledHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr auto;
  align-items: center;
  
  ${down('md')} {
   grid-template-columns: 150px 1fr auto;
   
   img {
    width: 150px;
   }
  }
  
  img {
    object-fit: cover;
  }
`;

const StyledContactButton = styled.div`
  background-color: black;
  padding: 0.5em 1em;
  color: white;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 2px;
  
  ${down('md')} {
    display: none;
  }
`;

const StyledMenu = styled.div`
  justify-self: center;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1em;
  align-items: center;
  text-transform: uppercase;
  
  ${down('md')} {
    display: none;
  }
  
  a {
    font-weight: 500;
    color: black;
  }
`;

const StyledBurgerIcon = styled.div`
  display: none;
  color: black;
  font-size: 1.5em;
  justify-self: flex-end;
  
  ${down('md')} {
    display: grid;
  }
`;

export {
    StyledHeader,
    StyledHeaderContainer,
    StyledContactButton,
    StyledMenu,
    StyledBurgerIcon
}