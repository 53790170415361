import React from 'react';
import Header from "../content/header/header";
import CopyRight from "../content/copyright/copyright";
import Projects from "../content/projects/projects";

function ProjectsLayout() {
    return (
        <>
            <Header/>
            <Projects/>
            <CopyRight/>
        </>
    )
}

export default ProjectsLayout