import React from "react";
import {StyledHero, StyledHeroContent3, StyledHeroContentText, StyledHeroContactGrid} from "./styledHero3";
import {StyledContent} from "../../styles/styledContent";
import {useTranslation} from "react-i18next";

function Hero3() {
    const {t} = useTranslation(['hero']);

    return (
        <StyledContent>
            <StyledHero>
                <StyledHeroContent3>
                    <StyledHeroContentText>
                        <h1>{t('3.head')}</h1>
                        <h3>{t('3.text')}</h3>
                    </StyledHeroContentText>
                    <StyledHeroContactGrid>
                        <div>
                            E-Mail
                            <a href={'mailto:hello@houseofcodes.de'} target={'_blank'} rel="noreferrer"><h2>hello@houseofcodes.de</h2></a>
                        </div>
                        <div>
                            Mobile
                            <a  href={'tel:+491722774474'} target={'_blank'} rel="noreferrer"><h2>+491722774474</h2></a>
                        </div>
                    </StyledHeroContactGrid>
                </StyledHeroContent3>
            </StyledHero>
        </StyledContent>
    )
}

export default Hero3