import React, {useCallback, useEffect} from "react";
import {StyledBurgerMenu, StyledBurgerMenuItem, StyledBurgerImage, StyledBurgerMenuItemSmall} from "./styledBurger";
import { motion } from "framer-motion"
import {ArrowRightShort, XCircle} from "react-bootstrap-icons";
import {useTranslation} from "react-i18next";
import {StyledContent} from "../../styles/styledContent";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {StyledExitButton} from "../common/styledCommon";

function BurgerMenu(props) {

    const { setSlideIn } = props;
    const { t, i18n } = useTranslation(['burger']);
    const lang = i18n.language;

    const escFunction = useCallback((event) => {
        if(event.keyCode === 27) {
            setSlideIn(false)
        }
    }, [setSlideIn]);

    useEffect(() => {
        window.addEventListener('keydown', escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    })

    const switchLanguage = () => {
        i18n.changeLanguage(lang === 'de' ? 'en' : 'de').then(() => true);
        document.documentElement.lang = i18n.language
    };

    const list = {
        visible: {
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
        hidden: {
            transition: {
                when: "afterChildren",
            },
        },
    }
    let item = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

    return (
        <StyledContent>
            <StyledBurgerMenu>
                <StyledBurgerImage>
                </StyledBurgerImage>
                <div>
                    <StyledExitButton onClick={() => setSlideIn(false)}>
                        <h4>{t('head')}</h4>
                        <div className={'closeButton'}><XCircle/></div>
                    </StyledExitButton>
                    <motion.ul
                        initial="hidden"
                        animate="visible"
                        variants={list}
                    >
                        <motion.li variants={item}>
                            <Link to={'/'} onClick={() => setSlideIn(false)}>
                                <StyledBurgerMenuItem>
                                    <h2 className={'outlined'}>{t('house')}</h2>
                                    <div className={'arrowIcon'}><ArrowRightShort/></div>
                                </StyledBurgerMenuItem>
                            </Link>
                        </motion.li>
                        <motion.li variants={item}>
                            <Link to={'/projects'} onClick={() => setSlideIn(false)}>
                                <StyledBurgerMenuItem>
                                    <h2>{t('projects')}</h2>
                                    <div className={'arrowIcon'}><ArrowRightShort/></div>
                                </StyledBurgerMenuItem>
                            </Link>
                        </motion.li>
                        <motion.li variants={item}>
                            <Link to={'/contact'} onClick={() => setSlideIn(false)}>
                                <StyledBurgerMenuItem>
                                    <h2 className={'outlined'}>{t('contact')}</h2>
                                    <div className={'arrowIcon'}><ArrowRightShort/></div>
                                </StyledBurgerMenuItem>
                            </Link>
                        </motion.li>
                        <div className={'bottomTop'}>
                            <motion.li variants={item}>
                                <Link to={'/imprint'} onClick={() => setSlideIn(false)}>
                                    <StyledBurgerMenuItemSmall>
                                        <h4>{t('imprint')}</h4>
                                    </StyledBurgerMenuItemSmall>
                                </Link>
                            </motion.li>
                        </div>
                        <motion.li variants={item}>
                            <div className={'burgerFooter'}>
                                <h5>© HOUSE OF CODES | 2020 - {dayjs().format('YYYY')}</h5>
                                <h5 onClick={() => switchLanguage()}>
                                    { lang === 'de' ? 'DE' : 'EN' }
                                </h5>
                            </div>
                        </motion.li>
                    </motion.ul>
                </div>
            </StyledBurgerMenu>
        </StyledContent>
    )
}

export default BurgerMenu