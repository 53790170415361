import { createGlobalStyle } from 'styled-components'
import Helvetica from '../fonts/HelveticaNowText-Regular.otf';
import HelveticaLight from '../fonts/HelveticaNowText-Light.otf';
import HelveticaExtraBold from '../fonts/HelveticaNowText-Bold.otf';
import HelveticaBold from '../fonts/HelveticaNowText-Bold.otf';
import HelveticaBlack from '../fonts/HelveticaNowText-Black.otf';
import {down} from "styled-breakpoints";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Helvetica Now Text', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    font-weight: 500;
    overflow-x: hidden !important;
    -webkit-text-size-adjust: 100%;

    a {
      text-decoration: none;
    }
  }
  
  @font-face {
    font-family: 'Helvetica Now Text';
    src:url(${HelveticaLight}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Now Text';
    src:url(${Helvetica}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Now Text';
    src:url(${HelveticaBold}) format('opentype');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Helvetica Now Text';
    src:url(${HelveticaExtraBold}) format('opentype');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Helvetica Now Text';
    src:url(${HelveticaBlack}) format('opentype');
    font-weight: 900;
  }

  h3 {
    line-height: 1.2;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    
    ${down('md')} {
      font-size: 18px;
    }
  }
  
  h2 {
    margin: 0;
    font-size: 32px;
    line-height: 1.4;
    font-weight: 400;
    
    ${down('md')} {
      font-size: 22px;
    }
  }
  
  h4 {
    line-height: 1.2;
    font-weight: 500;
    margin: 0;
    font-size: 16px;
  }
  
  h1 {
    margin: 0;
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2;
    
    ${down('md')} {
      font-size: 48px;
    }
  }
  
  ul {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;