import React from 'react';
import Header from "../content/header/header";
import CopyRight from "../content/copyright/copyright";
import Hero3 from "../content/hero3/hero3";
import Imprint from "../content/imprint/imprint";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

function ContactLayout() {
    const {t} = useTranslation('helmet');

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('contact')}</title>
            </Helmet>
            <Header/>
            <Hero3/>
            <Imprint/>
            <CopyRight/>
        </>
    )
}

export default ContactLayout