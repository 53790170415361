import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledHero = styled.div`
  padding: 2em;
  
  ${down('md')} {
    padding: 0;
  }
`;

const StyledHeroContent3 = styled.div`
  background-color: black;
  position: relative;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 8em;
  align-content: center;
  
  ${down('md')} {
    padding: 4em 2em;
    min-height: auto;
  }
`;

const StyledHeroContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-top: 1px solid ${props => props.theme.common.green.base};
  padding-top: 2em;
  color: white;

  a {
    color: white;
  }
  
  ${down('sm')} {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }
  
  .item {
    display: grid;
    grid-row-gap: 0.5em;
  }
`;

const StyledHeroContentText = styled.div`
  color: white;
  display: grid;
  align-items: flex-start;
  
  h3 {
    margin-bottom: 2em;
    opacity: 0.5;
  }
`;

export { StyledHero, StyledHeroContactGrid, StyledHeroContent3, StyledHeroContentText }