import React from "react";
import {StyledMenu} from "./styledHeader";
import {Link} from "react-router-dom";

function Menu() {
    return (
        <StyledMenu>
            <Link to={'/'}>House</Link>
            <Link to={'/projects'}>Projekte</Link>
            <Link to={'/about'}>About</Link>
        </StyledMenu>
    )
}

export default Menu