import styled from 'styled-components';

const StyledSlideIn = styled.div`
  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: ${props => props.theme.slideIn.background};
    z-index: 999;
  }
`;

const StyledSlideInContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${props => props.theme.slideIn.background};
`;

const StyledExitButton = styled.div`
  color: ${props => props.theme.slideIn.close};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid ${props => props.theme.slideIn.close};
  
  h4 {
    font-weight: 500;
    letter-spacing: 3px;
  }
   
  .closeButton {
    font-size: 1.5em;
    cursor: pointer;
  }
`;

const StyledNotFound = styled.div`
  min-height: 400px;
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  grid-row-gap: 2em;
  padding-bottom: 4em;
  
  a {
    color: black;
  }
  
  img {
    object-fit: cover;
    max-width: 400px;
  }
`;

const StyledCookieModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.85);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCookieModalContent = styled.div`
  border-radius: 8px;
  background: ${props => props.theme.cookieBanner.background};
  letter-spacing: 1px;
  max-width: 500px;
  font-size: 14px;
  color: ${props => props.theme.cookieBanner.color};
  position: relative;

  .outlined {
    -webkit-text-stroke: 0.8px ${props => props.theme.cookieBanner.color};
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
  }
  
  .closeButton {
    font-size: 1.5em;
    color: ${props => props.theme.cookieBanner.color};
    position: absolute;
    top: 1em;
    cursor: pointer;
    right: 1em;
  }
  
  .explainer {
    margin-top: 1em;
    font-weight: 500;

    .link {
      color: ${props => props.theme.cookieBanner.color};
      opacity: 0.4;
      font-weight: 600;
    }
    
  }
  
  h2 {
    text-transform: uppercase;
    font-weight: 600;
    color: ${props => props.theme.cookieBanner.color};
  }
  
  .buttons {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 1em;
    align-items: center;
    margin-top: 2em;
  }
  
  
  .decline {
    background: ${props => props.theme.cookieBanner.background};
    color: ${props => props.theme.cookieBanner.border};
    border: 2px solid ${props => props.theme.cookieBanner.border};
    padding: 0.5em 1em;
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .accept {
    background: ${props => props.theme.cookieBanner.color};
    color: ${props => props.theme.cookieBanner.background};
    padding: 0.5em 1em;
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  padding: 2em;
`;

export { StyledSlideIn, StyledNotFound, StyledSlideInContainer, StyledCookieModal, StyledCookieModalContent, StyledExitButton}