import React  from "react";
import {StyledSlideIn, StyledSlideInContainer} from "./styledCommon";
import { motion, AnimatePresence } from "framer-motion"
import BurgerMenu from "../burger/burger";

function SlideInMenu(props) {

    const { isOpen, setSlideIn } = props;

    const variants = {
        visible: { y: 0 },
        hidden: {  y: 800 }
    }

    return (
        <StyledSlideIn>
            <AnimatePresence>
                { isOpen && (
                    <motion.div
                        className={'background'}
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        exit={{ opacity: 0, y: 800 }}
                        transition={{ ease: "easeOut", duration: 0.3 }}
                        key={'slideIn'}
                    >
                        <StyledSlideInContainer>
                            <BurgerMenu setSlideIn={setSlideIn} />
                        </StyledSlideInContainer>
                    </motion.div>
                )}
            </AnimatePresence>
        </StyledSlideIn>
    );
}

export default SlideInMenu