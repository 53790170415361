import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledHero = styled.div`
  padding: 2em;
  
  ${down('md')} {
   padding: 0; 
  }
`;

const StyledHeroContent = styled.div`
  min-height: 520px;
  background-color: ${props => props.theme.common.green.base};
  position: relative;
  border-radius: 2px;
  
  ${down('md')} {
    border-radius: 0;
    display: grid;
    min-height: auto;
    padding: 4em 2em;
  }
`;

const StyledHeroIcon = styled.div`
  position: absolute;
  right: 0;
  top: -10%;
  
  ${down('md')} {
    right: 4em;
    
    img {
      width: 300px !important;
    }
  }
  
  ${down('sm')} {
    right: -12em;
    display: none;
  }
  
  img {
    width: 350px;
  }
`;

const StyledHeroContentText = styled.div`
  color: black;
  position: absolute;
  bottom: 4em;
  left: 4em;
  display: grid;
  grid-row-gap: 2em;
  
  ${down('md')} {
    max-width: 500px;
    position: relative;
    bottom: auto;
    left: auto;
    align-self: center;
  }
`;

export { StyledHero, StyledHeroIcon, StyledHeroContent, StyledHeroContentText }