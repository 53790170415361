import React from "react";
import {StyledHero, StyledHeroContent2, StyledHeroIcon, StyledHeroContentText} from "./styledHero2";
import {StyledContent} from "../../styles/styledContent";
import {useTranslation} from "react-i18next";
import {useTransform, useViewportScroll, motion} from "framer-motion";
import Coffee from "../../styles/coffee.png";

function Hero2() {
    const {t} = useTranslation(['hero']);
    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY, [0,1000], [-300, 0]);
    
    return (
            <StyledContent>
                <StyledHero>
                    <StyledHeroContent2>
                        <StyledHeroIcon>
                            <motion.div
                                className="box"
                                style={{ y: y2 }}
                            >
                                <img draggable={false} src={Coffee} alt={'Coffee'}/>
                            </motion.div>
                        </StyledHeroIcon>
                        <StyledHeroContentText>
                            <h1>{t('2.head')}</h1>
                            <h3>{t('2.head_1')}</h3>
                        </StyledHeroContentText>
                    </StyledHeroContent2>
                </StyledHero>
            </StyledContent>
    )
}

export default Hero2