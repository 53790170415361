import React from "react";
import {StyledContent} from "../../styles/styledContent";
import {StyledBenefit, StyledBenefitContent, StyledBenefitItem, StyledBenefitHeadline, StyledBenefits} from "./styledBenefit";
import {useTranslation} from "react-i18next";
import Rocket from '../../styles/rocket.png';

function Benefit() {
    const {t} = useTranslation(['benefit']);

    return (
        <StyledBenefit>
            <StyledContent>
                <StyledBenefitContent>
                    <StyledBenefitHeadline>
                        <img draggable={false} src={Rocket} alt={'rocket'}/>
                    </StyledBenefitHeadline>
                    <StyledBenefits>
                        <StyledBenefitItem>
                            <h3>{t('items.programming.head')}</h3>
                            <div>{t('items.programming.text')}</div>
                            <div>{t('items.programming.text_1')}</div>
                            <div>{t('items.programming.text_2')}</div>
                        </StyledBenefitItem>
                        <StyledBenefitItem>
                            <h3>{t('items.graphic.head')}</h3>
                            <div>{t('items.graphic.text')}</div>
                            <div>{t('items.graphic.text_1')}</div>
                            <div>{t('items.graphic.text_2')}</div>
                        </StyledBenefitItem>
                        <StyledBenefitItem>
                            <h3>{t('items.video.head')}</h3>
                            <div>{t('items.video.text')}</div>
                            <div>{t('items.video.text_1')}</div>
                            <div>{t('items.video.text_2')}</div>
                        </StyledBenefitItem>
                        <StyledBenefitItem>
                            <h3>{t('items.concept.head')}</h3>
                            <div>{t('items.concept.text')}</div>
                            <div>{t('items.concept.text_1')}</div>
                            <div>{t('items.concept.text_2')}</div>
                        </StyledBenefitItem>
                    </StyledBenefits>
                </StyledBenefitContent>
            </StyledContent>
        </StyledBenefit>
    )
}

export default Benefit