import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledCopyright = styled.div`
  display: grid;
  grid-template-columns: 120px auto 1fr auto auto auto auto;
  align-items: center;
  grid-column-gap: 1em;
  padding: 2em 2em;
  border-top: 2px solid #eaeaeb;
  font-size: 1.2em;
  
  .copyRightMenu {
    font-size: 14px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 0.5em;
    opacity: 0.5;
    justify-self: center;
    cursor: pointer;
    
    ${down('sm')} {
      display: none;
    }
  }
  
  img {
    width: 120px;
  }
  
  a {
    color: black;
  }

  img {
    width: 120px;
  }
  
  ${down('md')} {
    grid-template-columns: 120px auto 1fr auto auto auto auto;
    font-size: 1em;
    padding: 2em;
    grid-column-gap: 0.5em;
    
    img {
      width: 120px;
    }
  }
  
  ${down('sm')} {
      grid-template-columns: 120px 1fr auto auto auto auto;
  }
  
  .icon {
    cursor: pointer;
  }
  
  img {
    object-fit: cover;
  }
`;

export default StyledCopyright;