import React from "react";
import {StyledContent} from "../../styles/styledContent";
import {StyledProjects, StyledProjectsContent, StyledProjectsContentItemMiddle, StyledProjectsContentItem} from "./styledProjects";
import {ReactComponent as MentLogo} from '../../styles/ment.svg';
import {ReactComponent as SavoyLogo} from '../../styles/savoy.svg';
import {ReactComponent as SchatzLogo} from '../../styles/schatz.svg';

function Projects() {
    return (
        <StyledContent>
            <StyledProjects>
                <StyledProjectsContent>
                    <StyledProjectsContentItem>
                        <div className={'logo'}>
                            <SavoyLogo/>
                        </div>
                        <div className={'customer'}>
                            <div className={'content'}>
                                <h3>Club Savoy Göttingen</h3>
                                <h4>Complete Branding</h4>
                                <h4>Website</h4>
                                <h4>Social Media</h4>
                            </div>
                        </div>
                    </StyledProjectsContentItem>
                    <StyledProjectsContentItemMiddle>
                        <div className={'customer'}>
                            <div className={'customer'}>
                                <div className={'content'}>
                                    <h3>Thüringer Mediennetzwerk</h3>
                                    <h4>Logo Design</h4>
                                </div>
                            </div>
                        </div>
                        <div className={'logo'}>
                            <MentLogo/>
                        </div>
                    </StyledProjectsContentItemMiddle>
                    <StyledProjectsContentItem>
                        <div className={'logo'}>
                            <SchatzLogo/>
                        </div>
                        <div className={'customer'}>
                            <div className={'content'}>
                                <h3>Schatz Genuss</h3>
                                <h4>Complete Branding</h4>
                                <h4>Website</h4>
                                <h4>Social Media</h4>
                            </div>
                        </div>
                    </StyledProjectsContentItem>
                </StyledProjectsContent>
            </StyledProjects>
        </StyledContent>
    )
}

export default Projects