import React from "react";
import StyledCopyright from "./styledCopyRight";
import Logo from '../../styles/hof_logo.svg';
import {StyledContent} from "../../styles/styledContent";
import { Facebook, Whatsapp, Linkedin, Instagram } from 'react-bootstrap-icons';
import {Link} from "react-router-dom";

function CopyRight(){
    return (
        <StyledContent>
            <StyledCopyright>
                <img draggable={false} src={Logo} alt={'logo'}/>
                <div>© 2021</div>
                <div className={'copyRightMenu'}>
                    <Link to={'/contact'}>Impressum</Link>
                    <Link to={'/contact'}>Cookies</Link>
                </div>
                <a href={'https://instagram.com/houseofcodes'} target={'_blank'} rel="noreferrer" className={'icon'}><Instagram/></a>
                <a href={'https://facebook.com/houseofcodes'} target={'_blank'} rel="noreferrer" className={'icon'}><Facebook/></a>
                <a href={'https://wa.me/491722774474'} target={'_blank'} rel="noreferrer" className={'icon'}><Whatsapp/></a>
                <a href={'https://linkedin.com/company/houseofcodes'} target={'_blank'} rel="noreferrer" className={'icon'}><Linkedin/></a>
            </StyledCopyright>
        </StyledContent>
    )
}

export default CopyRight