import React from 'react';
import Header from "../content/header/header";
import Hero from "../content/hero/hero";
import Benefit from "../content/benefit/benefit";
import Hero2 from "../content/hero2/hero2";
import Testimonials from "../content/testimonials/testimonials";
import CopyRight from "../content/copyright/copyright";
import Hero3 from "../content/hero3/hero3";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

function HomeLayout() {
    const {t} = useTranslation('helmet');

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('home')}</title>
            </Helmet>
            <Header/>
            <Hero/>
            <Benefit/>
            <Hero2/>
            <Testimonials/>
            <Hero3/>
            <CopyRight/>
        </>
    )
}

export default HomeLayout