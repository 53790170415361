import React, {useState} from "react";
import {StyledCookieModal, StyledCookieModalContent} from "./styledCommon";
import Cookies from 'js-cookie'
import {useTranslation} from "react-i18next";
import {XCircle} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

function CookieModal() {

    let acceptCookiePolicy = Cookies.get('acceptCookiePolicy');
    const[isVisible, setIsVisible] = useState(acceptCookiePolicy === undefined);
    const { t } = useTranslation(['site']);

    const onClose = () => {
        setIsVisible(false)
    }

    const onAccept = () => {
        Cookies.set('acceptCookiePolicy', 'true', { expires: 365 });
        setIsVisible(false)
    }

    if (!isVisible) { return <div/>}

    return (
        <StyledCookieModal>
            <StyledCookieModalContent>
                <div className={'closeButton'}
                    onClick={() => onClose()}
                >
                    <XCircle/>
                </div>
                <div>
                    <h2>{ t('cookies.banner.head_1') }<span>🍪</span></h2>
                </div>
                <div className={'explainer'}>
                    <div>{ t('cookies.banner.explainer') }</div>
                    <Link className={'link'} to={'/cookies'}>{t('cookies.banner.policy')}</Link>
                </div>
                <div className={'buttons'}>
                    <div className={'decline'} onClick={() => onAccept()}>{t('cookies.banner.decline')}</div>
                    <div className={'accept'} onClick={() => onAccept()}>{t('cookies.banner.accept')}</div>
                </div>
            </StyledCookieModalContent>
        </StyledCookieModal>
    )
}

export default CookieModal