import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

i18n
    .use(detector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        detection: {
            caches: ['localStorage']
        },
        preload: ['de', 'en'],
        fallbackLng: 'de',
        ns: ['de', 'en'],
        debug: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;