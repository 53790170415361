import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledBenefit = styled.div`
`;

const StyledBenefitContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 6em 0;
  grid-gap: 4em;
  
  ${down('md')} {
    grid-template-columns: 1fr;
    padding: 4em 2em;
  }
`;

const StyledBenefitHeadline = styled.div`
  display: flex;
  justify-content: center;
  
  ${down('md')} {
    display: none;
  }
  
  img {
    height: 100%;
    object-fit: cover;
  }
`;

const StyledBenefits = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  
  ${down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const StyledBenefitItem = styled.div`
  display: grid;
  grid-row-gap: 0.5em;
  
  h3 {
    font-weight: 600;
  }
`;

export { StyledBenefit, StyledBenefitItem, StyledBenefitContent, StyledBenefitHeadline, StyledBenefits }