import React from "react";
import {StyledContent} from "../../styles/styledContent";
import {StyledTestimonial, StyledTestimonialText, StyledTestimonialPicture} from "./styledTestimonials";
import Henryk from '../../styles/henryk.png';
import Bubble from '../../styles/bubble.png';

function Testimonials() {
    return (
        <StyledContent>
            <StyledTestimonial>
                <div className={'bubble-image'}>
                    <img draggable={false} src={Bubble} alt={'bubble'}/>
                </div>
                <StyledTestimonialText>
                    <h2>"Ich setze seit 5 Jahren auf den zeitgemäßen Geschmack der beiden Brüder. Sie arbeiten dazu exakt, zügig und zuverlässig wie ein Schweizer Uhrwerk. Symphatisches und kreatives  Familienunternemen aus Thüringen"</h2>
                    <StyledTestimonialPicture>
                        <img draggable={false} src={Henryk} alt={'henryk'}/>
                        <div>
                            <h3>Dr. Henryk Balkow</h3>
                            Dozent, Journalist, Projektmanager und Produzent
                        </div>
                    </StyledTestimonialPicture>
                </StyledTestimonialText>
            </StyledTestimonial>
        </StyledContent>
    )
}

export default Testimonials