import React from "react";
import AppThemeProvider from "./content/common/themeProvider";
import * as smoothscroll from 'smoothscroll-polyfill';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import commonReducer from "./content/common/reducer";
import './i18n';
smoothscroll.polyfill();

const Store = createStore(
    combineReducers({commonReducer})
);

function App() {
    return (
        <Provider store={Store}>
            <AppThemeProvider/>
        </Provider>
    );
}

export default App;