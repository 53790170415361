import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledTestimonial = styled.div`
  padding: 4em 2em;
  display: grid;
  grid-row-gap: 2em;
  grid-template-columns: 1fr 2fr;
  
  ${down('md')} {
    padding: 4em 2em;
    grid-template-columns: 1fr;
    
    .bubble-image {
      display: none;
    }
  }
  
`;

const StyledTestimonialText = styled.div`
  display: grid;
  grid-row-gap: 2em;
`;

const StyledTestimonialPicture = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  justify-items: flex-start;
  align-items: center;
  grid-column-gap: 2em;
  font-weight: 400;
  
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  
  ${down('md')} {
    grid-template-columns: 100px 1fr;
    img {
      height: 100px;
      width: 100px;
    }
  }
`;

export { StyledTestimonial, StyledTestimonialText, StyledTestimonialPicture}