import styled from "styled-components";
import {down} from 'styled-breakpoints';

const StyledBurgerMenu = styled.div`
  padding: 1em 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 4em;
  margin-top: 5vh;
    
  ${down('md')} {
    grid-template-columns: 1fr;
  }
  
  .bottomTop {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${props => props.theme.slideIn.close};
  }
  
  .burgerFooter {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${props => props.theme.slideIn.close};
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 1em;
    align-items: center;
    
    h5 {
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: ${props => props.theme.slideIn.close};
    }
  }
`;

const StyledBurgerImage = styled.div`
  border-radius: 4px;
  background: url("https://savoy-files.s3.eu-central-1.amazonaws.com/club-savoy-menu");
  background-position: center;
  background-size: cover;
  height: 100%;
  
  ${down('md')} {
    display: none;
  }
`;

const StyledBurgerMenuItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-column-gap: 0.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 0.5em;
  cursor: pointer;
  color: ${props => props.theme.slideIn.color};
  
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 1;
  }
  
  .arrowIcon {
    font-size: 2em;
  }
`;

const StyledBurgerMenuItemSmall = styled(StyledBurgerMenuItem)`
  h4 {
    color: ${props => props.theme.slideIn.close} !important;
    font-weight: 500;
  }
`;

export { StyledBurgerMenu, StyledBurgerMenuItem, StyledBurgerImage, StyledBurgerMenuItemSmall }