import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledImprint = styled.div`
  padding: 2em 2em 4em 2em;
`;

const StyledDataPrivacy = styled.div`
  padding: 0 2em 4em 2em;
  
  h4 {
    line-height: 1.5;
    opacity: 0.5;
  }
  
  h3 {
    margin-bottom: 0.5em;
  }
`;

const StyledDataPrivacyContent = styled.div`
  display: grid;
  grid-row-gap: 1em;
  margin-top: 1em;
`;

const StyledDataPrivacyContentParagraph = styled.div`
  display: grid;
  grid-row-gap: 1em;
`;

const StyledImprintHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  align-items: flex-start;
  
  ${down('md')} {
    grid-template-columns: 1fr 1fr;
  }
  
  ${down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const StyledImprintHeaderItem = styled.div`
  display: grid;
  grid-column-gap: 0.5em;
  align-items: flex-start;
  
  h4 {
    line-height: 1.5;
    opacity: 0.5;
  }
`;

export {
    StyledImprint,
    StyledImprintHeader,
    StyledImprintHeaderItem,
    StyledDataPrivacy,
    StyledDataPrivacyContent,
    StyledDataPrivacyContentParagraph
}