import React from 'react';
import Header from "../content/header/header";
import CopyRight from "../content/copyright/copyright";
import {StyledContent} from "../styles/styledContent";
import {StyledNotFound} from "../content/common/styledCommon";
import Compass from '../styles/compass.png';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

function NotFoundLayout() {
    const {t} = useTranslation('helmet');

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('notFound')}</title>
            </Helmet>
            <Header/>
            <StyledContent>
                <StyledNotFound>
                    <img draggable={false} src={Compass} alt={'Compass'}/>
                    <Link to={'/'}>Diese Seite existiert leider nicht oder befindet sich im Aufbau!<br/>Zurück</Link>
                </StyledNotFound>
            </StyledContent>
            <CopyRight/>
        </>
    )
}

export default NotFoundLayout