import {lightTheme} from "../../styles/lightTheme";
import {darkTheme} from "../../styles/darkTheme";
import {SWITCH_THEME, TRIGGER_LOADING} from "./actions";

const localTheme = window.localStorage.getItem('theme');

const activeTheme = () => {
    let isDarkTheme = ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) && localTheme !== 'light') ||
        localTheme === 'dark';
    return isDarkTheme ? darkTheme : lightTheme;
};

const initialState = {
    theme: activeTheme()
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_THEME:
            let theme;
            if (action.payload === 'light') {
                window.localStorage.setItem('theme', 'dark');
                theme = { ...darkTheme};
            } else {
                window.localStorage.setItem('theme', 'light');
                theme = { ...lightTheme};
            }
            return {
                ...state,
                theme: theme
            };
        case TRIGGER_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
};

export default commonReducer;