import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import MainLayout from "./layouts/main";
import HomeLayout from "./layouts/home";
import ContactLayout from "./layouts/contact";
import NotFoundLayout from "./layouts/notFound";
import ProjectsLayout from "./layouts/projects";

function Routes() {
    return (
        <Router>
            <Switch>
                    <Route key={1} exact path={'/'}>
                        <MainLayout components={[HomeLayout]}/>
                    </Route>
                <Route key={1} exact path={'/contact'}>
                    <MainLayout components={[ContactLayout]}/>
                </Route>
                <Route key={1} exact path={'/projects'}>
                    <MainLayout components={[ProjectsLayout]}/>
                </Route>
                <Route key={1} exact path={'/about'}>
                    <MainLayout components={[ContactLayout]}/>
                </Route>
                <Route key={1} exact path={'/imprint'}>
                    <MainLayout components={[ContactLayout]}/>
                </Route>
                <Route key={5} exact path={'*'}>
                    <MainLayout components={[NotFoundLayout]}/>
                </Route>
            </Switch>
        </Router>
    );
}

export default Routes;