import React from 'react';
import CookieModal from "../content/common/cookieModal";

function MainLayout({ components }) {

    return (
        <>
            { components.map((Component, i) => {
                return <Component key={i}/>
            })}
            <CookieModal/>
        </>
    )
}

export default MainLayout;