import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledProjects = styled.div`
  padding: 2em 2em 4em 2em;
`;

const StyledProjectsContent = styled.div`
  display: grid;
  margin-top: 1em;
  ${down('md')} {
    grid-row-gap: 2em;  
  }
`;

const StyledProjectsContentItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  ${down('md')} {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
  
  .logo {
    border-radius: 2px;
    min-height: 350px;
    background-color: black;
    display: grid;
    align-items: center;
    justify-content: center;

    svg {
      fill: white;
      width: 100%;
    }
  }
  
  .customer {
    display: grid;
    align-items: center;
    justify-content: center;
    
    h3 {
      margin-bottom: 1em;
    }
    
    ${down('md')} {
      justify-content: left;
      
      h3 {
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    
    .content {
      display: grid;
      grid-row-gap: 0.5em;
    }
  }
`;

const StyledProjectsContentItemMiddle = styled(StyledProjectsContentItem)`
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "customer logo";
    align-items: center;
    
    ${down('md')} {
        grid-template-columns: 1fr !important;
        grid-template-areas: "logo"
                              "customer";
    }
    
    .logo {
      grid-area: logo;
    }
    
    .customer {
      grid-area: customer;
    }
`;

export {
    StyledProjects,
    StyledProjectsContent,
    StyledProjectsContentItem,
    StyledProjectsContentItemMiddle
}