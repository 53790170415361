import React from 'react';
import Routes from "../../Routes";
import { ThemeProvider } from "styled-components";
import GlobalStyle from '../../styles/globalStyle';
import { useSelector } from 'react-redux'

function AppThemeProvider() {

    const theme = useSelector(state => state.commonReducer.theme)

    return (
        <ThemeProvider theme={theme}>
            <Routes/>
            <GlobalStyle/>
        </ThemeProvider>
    );
}

export default AppThemeProvider;