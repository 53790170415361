export const SWITCH_THEME = "SWITCH_THEME";
export const TRIGGER_LOADING = "TRIGGER_LOADING";

export const switchTheme = payload => {
    return dispatch => {
        dispatch({
            type: SWITCH_THEME,
            payload: payload
        })
    }
};

export const triggerLoading = payload => {
    return dispatch => {
        dispatch({
            type: TRIGGER_LOADING,
            payload: payload
        })
    }
};