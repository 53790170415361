import styled from 'styled-components';
import {down} from "styled-breakpoints";

const StyledHero = styled.div`
  padding: 2em;
  
  ${down('md')} {
    padding: 0;
  }
`;

const StyledHeroContent2 = styled.div`
  min-height: 450px;
  background-color: black;
  position: relative;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 2em 4em;
  align-content: center;
  
  ${down('md')} {
    padding: 4em 2em;
    grid-template-columns: 1fr;
    min-height: auto;
  }
`;

const StyledHeroIcon = styled.div`
  position: absolute;
  right: -1em;
  bottom: 0;
  transform: rotate(20deg);
  
  ${down('sm')} {
    display: none;
  }
  
  ${down('md')} {
    right: 2em;
    bottom: -2em;
    img {
      width: 250px !important;
    }
  }
  
  img {
    width: 330px;
  }
`;

const StyledHeroContentText = styled.div`
  color: white;
  display: grid;
  align-items: flex-start;
  
  h3 {
    margin-bottom: 2em;
    line-height: 1.5;
    margin-top: 1em;
    color: ${props => props.theme.common.green.base};
`;

export { StyledHero, StyledHeroIcon, StyledHeroContent2, StyledHeroContentText }