import React, {useState, useEffect} from "react";
import {StyledHeader, StyledHeaderContainer, StyledContactButton, StyledBurgerIcon} from "./styledHeader";
import {StyledContent} from "../../styles/styledContent";
import Logo from '../../styles/hof_logo.svg';
import Menu from "./menu";
import { List } from 'react-bootstrap-icons';
import SlideInMenu from "../common/slideIn";
import {Link} from "react-router-dom";

function Header() {
    const [showHeaderBlur, setHeaderBlur] = useState(false);
    const [showSlideIn, setSlideIn] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.pageYOffset > 64 && !showHeaderBlur) {
                setHeaderBlur(true)
            }
            if (window.pageYOffset < 64 && showHeaderBlur) {
                setHeaderBlur(false)
            }
        }
        window.addEventListener('scroll', handleResize);
    })

    return (
        <StyledHeader showHeaderBlur={showHeaderBlur}>
            <StyledContent>
                <StyledHeaderContainer>
                    <Link to={'/'}>
                        <img draggable={false} src={Logo} alt={'logo'}/>
                    </Link>
                    <Menu/>
                    <Link to={'/contact'}>
                        <StyledContactButton>
                            Kontakt
                        </StyledContactButton>
                    </Link>
                    <StyledBurgerIcon onClick={() => setSlideIn(!showSlideIn)}>
                        <List/>
                    </StyledBurgerIcon>
                </StyledHeaderContainer>
            </StyledContent>
            <SlideInMenu isOpen={showSlideIn} setSlideIn={setSlideIn}/>
        </StyledHeader>
    )
}

export default Header